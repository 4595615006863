import { initFragmentOnClient } from 'lib/ssr-client-react'
import fragment from './index'

const fragmentName = 'searchInputFragment'

// DO NOT CHANGE UNLESS YOU REALLY KNOW WHAT YOU ARE DOING
initFragmentOnClient(fragment.Component, fragmentName)
if (module.hot) {
  module.hot.accept('./index', () => {
    const reload = require('./index').default
    initFragmentOnClient(reload.Component, fragmentName)
  })
}
