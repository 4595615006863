import { GET_SUGGESTIONS_SUCCESS } from 'client/fragments/searchInputFragment/store/actions'

export const suggestionsReducer = (state, action) => {
  switch (action.type) {
    case GET_SUGGESTIONS_SUCCESS:
      return { suggestions: action.suggestions, softContentSuggestions: action.softContentSuggestions }
    default:
      return state
  }
}
