import React, { useReducer } from 'react'
import { getTranslator } from '../../common/translator'
import SearchInputContainer from '../../common/components/Search/SearchInputContainer/SearchInputContainer'
import { suggestionsReducer } from 'client/fragments/searchInputFragment/store/reducer'
import { onSuggestionsChange } from 'client/fragments/searchInputFragment/store/actions'
import { ChemistryFragmentProvider } from 'chemistry'

const Component = props => {
  const [suggestionStore, dispatch] = useReducer(suggestionsReducer, {
    suggestions: props.suggestions,
    softContentSuggestions: props.softContentSuggestions
  })
  const { format, lang, ipAddress } = props
  const t = getTranslator(props.translations)

  return (
    <ChemistryFragmentProvider>
      <SearchInputContainer
        {...{
          ...props,
          t,
          suggestions: suggestionStore.suggestions,
          softContentSuggestions: suggestionStore.softContentSuggestions,
          onSuggestionsChange: onSuggestionsChange({ format, lang, ipAddress, dispatch })
        }}
      />
    </ChemistryFragmentProvider>
  )
}

export default { Component }
